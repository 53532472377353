<template>
  <div class="preview-grid">
    <div class="preview-grid__label" v-if="options.hasPromo && options.promotion_type">{{promotionTypeText}}</div>
    <img :src="options.avatar" class="preview-grid__img"/>
    <div class="preview-grid__content">
      <h3>{{options.title || 'Product Title'}}</h3>
      <div class="preview-grid__shop-name">
        <img src="/images/icons/shop.svg"/>
        <p>{{ options.merchant_name }}</p>
      </div>
    </div>
    <div class="preview-grid__price">
      <img :src="options.hasPoints ? '/images/icons/points.png' : '/images/icons/coin.png'"/>
      <strong :class="{ green: options.hasPoints }">{{options.hasPoints ? 'Points: ' + options.points : ( options.price ? $filters.formatPrice(options.price) : $filters.formatPrice(options.price_original || 0))}}</strong>
      <p v-if="options.price">{{$t('previews.original')}} <span>{{$filters.formatPrice(options.price_original || 0)}}</span></p>
    </div>
    <div class="preview-grid__icons">
      <img src="/images/icons/heart-red.png"/>
      <img src="/images/icons/share-gray.png"/>
      <img src="/images/icons/cart-gray.png"/>
    </div>
  </div>
</template>

<script>
import { promoTypes } from '@/config/config'
import _ from 'lodash'

export default {
  name: 'PreviewGrid',
  props: [
    'options'
  ],
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
    promotionTypeText () {
      if (this.options.promotion_type < 4) {
        return promoTypes[this.options.promotion_type] ? this.$t(promoTypes[this.options.promotion_type]) : null
      } else if (this.options.promotion_type == 5) {
        const off = _.round(Math.abs((this.options.price_original || 0) - (this.options.promotion_price || 0)), 2)

        return this.$t('global.xoff', { x: off })
      } else if (this.options.promotion_type == 4) {
        const off = _.round(Math.abs(1 - (this.options.promotion_rate || 1)), 2) * 100

        return this.$t('global.xperoff', { x: off })
      } else {
        return ''
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "style";
</style>
