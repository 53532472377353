<template>
  <div class="lang-switch" :class="{ on: lang === 'ch' }" @click="onChange">
    <div class="lang-switch__opt">EN</div>
    <div class="lang-switch__opt">中文</div>
    <div class="lang-switch__select"/>
  </div>
</template>

<script>

export default {
  name: 'LangSwitch',
  props: [
    'language'
  ],
  data () {
    return {
      lang: 'en'
    }
  },
  mounted () {
    this.lang = this.language
  },
  watch: {
    language (newVal) {
      this.lang = newVal
    }
  },
  methods: {
    onChange () {
      this.lang = this.lang === 'en' ? 'ch' : 'en'

      this.$emit('langSelect', this.lang)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "style";
</style>
