<template>
  <div class="preview-list">
    <div class="preview-list__top">
      <img :src="options.avatar" class="preview-list__img"/>
      <div class="preview-list__right">
        <h3><span v-if="options.hasPromo && options.promotion_type">{{promotionTypeText}}</span>{{options.title || 'Product Title'}}</h3>
        <div class="preview-list__shop-name">
          <img src="/images/icons/shop.svg"/>
          <p>{{ options.merchant_name }}</p>
        </div>
        <div class="preview-list__price">
          <img :src="options.hasPoints ? '/images/icons/points.png' : '/images/icons/coin.png'"/>
          <strong :class="{ green: options.hasPoints }">{{options.hasPoints ? 'Points: ' + options.points : ( options.price ? $filters.formatPrice(options.price) : $filters.formatPrice(options.price_original || 0))}}</strong>
          <p v-if="options.price">{{$t('previews.original')}} <span>{{$filters.formatPrice(options.price_original || 0)}}</span></p>
        </div>
        <div class="preview-list__icons">
          <img src="/images/icons/heart-red.png"/>
          <img src="/images/icons/share-gray.png"/>
          <img src="/images/icons/cart-gray.png"/>
        </div>
      </div>
    </div>
    <div class="preview-list__description" v-html="description"/>
  </div>
</template>

<script>
import { promoTypes } from '@/config/config'
import _ from 'lodash'

export default {
  name: 'PreviewList',
  props: [
    'options'
  ],
  data () {
    return {
    }
  },
  mounted () {
  },
  computed: {
    description () {
      return this.options.description ? this.options.description.replace(/(?:\r\n|\r|\n)/g, '<br>') : 'Description'
    },
    promotionTypeText () {
      if (this.options.promotion_type < 4) {
        return promoTypes[this.options.promotion_type] ? this.$t(promoTypes[this.options.promotion_type]) : null
      } else if (this.options.promotion_type == 5) {
        const off = _.round(Math.abs((this.options.price_original || 0) - (this.options.promotion_price || 0)), 2)

        return this.$t('global.xoff', { x: off })
      } else if (this.options.promotion_type == 4) {
        const off = _.round(Math.abs(1 - (this.options.promotion_rate || 1)), 2) * 100

        return this.$t('global.xperoff', { x: off })
      } else {
        return ''
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import "style";
</style>
